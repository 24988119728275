/**
 * 送信元ページ
 */
import { isBlank } from '@/utilities/typing'
import ChannelCategory from '@/models/enums/channel-category'
import Temperature from '@/models/enums/temperature'
import IsVisible from '@/models/enums/is-visible';

class DetailedChannel {
    detailed_channel_id;
    detailed_channel_name;
    temperature;
    channel_category;
    is_visible;

    constructor(properties) {
        if (isBlank(properties)) {
            properties = {};
        }

        this.detailed_channel_id = properties.detailed_channel_id;
        this.detailed_channel_name = properties.detailed_channel_name;
        this.temperature = properties.temperature ?? Temperature.HOT;
        this.channel_category = properties.channel_category ?? ChannelCategory.CONTACT;
        this.is_visible = properties.is_visible ?? IsVisible.SHOW
    }

    get channel_category_display() {
        return ChannelCategory.get(this.channel_category);
    }
}

export default DetailedChannel;

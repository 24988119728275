<template>
    <header class="page-title">
        <h1><i class="bi bi-gear-fill"></i> マスタ管理 - 送信元ページ</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else class="col-sm-36 col-md-32 col-lg-30">
            <table class="table">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-12">送信元ページ</th>
                        <th class="text-center col-4">HOT/COLD</th>
                        <th class="text-center col-4">反響分類</th>
                        <th class="text-center col-3">表示/非表示</th>
                        <th class="text-center col-3"></th>
                    </tr>
                </thead>
                <template v-if="detailed_channels.length">
                    <draggable
                            v-model="detailed_channels"
                            item-key="detailed_channel_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle">
                                    <form-input v-model="element.detailed_channel_name"/>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="element.temperature"
                                        :options="Temperature.options()"
                                    ></form-select>
                                </td>
                                <td class="align-middle">
                                    <form-select
                                        v-model="element.channel_category"
                                        :options="ChannelCategory.options()"
                                    ></form-select>
                                </td>
                                <td class="text-center">
                                    <form-select
                                        v-model="element.is_visible"
                                        :options="IsVisible.options()"
                                    ></form-select>
                                </td>
                                <td class="text-center">
                                    <button type="button" class="btn btn-info me-2" @click="update(element)">保存</button>
                                    <button type="button" class="btn btn-outline-danger ms-2" @click="removeReady(element)">削除</button>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
                <tfoot>
                    <tr>
                        <td></td>
                        <td class="align-middle"><form-input v-model="detailed_channel_to_create.detailed_channel_name" /></td>
                        <td class="align-middle">
                            <form-select
                                v-model="detailed_channel_to_create.temperature"
                                :options="Temperature.options()"
                            ></form-select>
                        </td>
                        <td class="align-middle">
                            <form-select
                                v-model="detailed_channel_to_create.channel_category"
                                :options="ChannelCategory.options()"
                            ></form-select>
                        </td>
                        <td class="text-center">
                            <button class="btn btn-info" @click="create">追加</button>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import DetailedChannel from '@/models/entities/detailed-channel';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import FormInput from '@/components/forms/FormInput';
import FormSelect from '@/components/forms/FormSelect';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import ChannelCategory from '@/models/enums/channel-category';
import Temperature from '@/models/enums/temperature';
import IsVisible from '@/models/enums/is-visible';

export default {
    name: 'MasterDetailedChannel',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        FormSelect,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // 反響分類option
            ChannelCategory,
            // HOT/COLD
            Temperature,
            // 表示/非表示
            IsVisible,
            // ローディング
            loading: false,
            // 表示データ
            detailed_channels: [],
            // 新規
            detailed_channel_to_create: new DetailedChannel(),
            // 削除
            detailed_channel_to_remove: null,
        }
    },
    mounted() {
        this.fetchDetailedChannels();
    },
    methods: {
        // 一覧取得
        fetchDetailedChannels() {
            //ローディング開始
            this.loading = true;

            //表示データ初期化
            this.detailed_channels.splice(0);
            this.$http.get('/master/detailed-channel')
            .then(response => {
                for (let row of response.data) {
                    this.detailed_channels.push(new DetailedChannel(row));
                }
            })
            .finally(() => {
                //ローディング終了
                this.loading = false;
            });
        },
        // 新規登録
        create() {
            // バリデーション
            if (this.$helper.isBlank(this.detailed_channel_to_create.detailed_channel_name)) {
                this.showErrorMessage('送信元ページ名を入力してください');
                return;
            }
            if (this.$helper.isBlank(this.detailed_channel_to_create.channel_category)) {
                this.showErrorMessage('反響分類を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.post('/master/detailed-channel', this.detailed_channel_to_create)
            .then(response => {
                this.detailed_channels.push(new DetailedChannel(response.data));
                this.detailed_channel_to_create = new DetailedChannel();
                this.showMessage('追加しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 保存（編集）
        update(detailed_channel) {
            // バリデーション
            if (this.$helper.isBlank(detailed_channel.detailed_channel_name)) {
                this.showErrorMessage('送信元ページ名を入力してください');
                return;
            }
            if (this.$helper.isBlank(detailed_channel.channel_category)) {
                this.showErrorMessage('反響分類を選択してください');
                return;
            }

            this.startScreenLoading();

            this.$http.put(`/master/detailed-channel/${detailed_channel.detailed_channel_id}`, detailed_channel)
            .then(() => {
                this.showMessage('保存しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 削除モーダル
        removeReady(detailed_channel) {
            this.detailed_channel_to_remove = detailed_channel;
            this.$refs.confirmRemove.show();
        },
        // 削除処理
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/master/detailed-channel/${this.detailed_channel_to_remove.detailed_channel_id}`)
            .then(() => {
                const remove_index = this.detailed_channels.findIndex((row) => row.detailed_channel_id === this.detailed_channel_to_remove.detailed_channel_id);
                this.detailed_channels.splice(remove_index, 1);
                this.detailed_channel_to_remove = null;
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 並び順更新
        sort() {
            this.startScreenLoading();

            const detailed_channels = this.detailed_channels.map(detailed_channel_object => detailed_channel_object.detailed_channel_id);

            this.$http.post('/master/detailed-channel/sort', {
                detailed_channels: detailed_channels,
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>

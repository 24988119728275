import Enum from './enum'

/**
 * 送信元ページ：表示フラグ
 */
class IsVisible extends Enum {
    static HIDDEN = 0; // 非表示
    static SHOW = 1; // 表示


    static values() {
        return {
            [this.HIDDEN]: '非表示',
            [this.SHOW]: '表示',
        }
    }
}

export default IsVisible;
